.App {
  text-align: center;
  color: #ffffff;
  min-height: 100vh;
}

.logo {
  height: 45%;
  width: 45%;
  max-width: 300px;
  min-height: 40%;

  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.generalPage {
  margin: "0 auto";
  display: "list-item";
  justify-content: "center";
  background-color: #054aac;
  min-height: 100vh;
}

.couplePage {
  margin: "0 auto";
  display: "list-item";
  justify-content: "center";
  background-color: #6c23a8;
  min-height: 100vh;
}

.App-header {
  background-color: #3d3e3f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sheet {
  align-self: center;
  max-width: 80vw;
  height: 70vh;
}

.stackRow {
  display: flex;

  justify-content: center;
  align-items: center;
}

.App-link {
  color: #ffffff;
  display: list-item;

  align-items: center;
  justify-content: center;
  background-color: #3c98d3;
  font-weight: bold;
  border-radius: 100px;
  padding: 3px 10px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 9px;
  max-height: fit-content;
  max-width: fit-content;

  border: 1px solid orange;
}

.h31 {
  color: rgb(228, 218, 218);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
